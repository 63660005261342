
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap.js'
import dynamicImportPolyfill from 'dynamic-import-polyfill'
import {resetCsrfCookieFromMeta} from "@js/utils/csrf";
dynamicImportPolyfill.initialize()
import '~sass/app.scss'
import '~sass/login.scss'
import '~sass/element-ui.scss'
/**
 * Import libraries
 */
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import Vue from 'vue'
import i18n from './i18n.js'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import http from './axios.js'
import router from './router.js'
import PortalVue from 'portal-vue'
import { VTooltip } from 'v-tooltip'
Vue.directive('tooltip', VTooltip)
window.Vue = Vue
import './authChecker.js'
import log from './logger'

Vue.use(PortalVue)
Vue.prototype.$http = http
Vue.prototype.$log = log

Vue.config.ignoredElements = ['jtk-source'];

/**
 * Configure language
 */
locale.use(lang)

/**
 * Import Google Charts
 */
import VueGoogleCharts from 'vue-google-charts'
Vue.use(VueGoogleCharts)

/**
 * Import Vee Validate
 */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { localize } from 'vee-validate'
import './rules/common'
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)


localize(window.vueLocale ? window.vueLocale : 'cs');

/**
 * Import Fragment
 */
import  { Plugin } from 'vue-fragment'
Vue.use(Plugin)

/**
 * Import Components
 */


Vue.component('vue-forms', () => import('./components/Forms/Form.vue'))
Vue.component('signup-form', () => import('./components/Signup/SignupForm.vue'))
Vue.component('automation-builder', () => import('./components/Automation/AutomationBuilder.vue'))
Vue.component('upload', () => import('./components/Upload/Upload.vue'))
Vue.component('am-chart', () => import('./components/Charts/AmChart.vue'))
Vue.component('campaign-report', () => import('./components/Charts/CampaignReport.vue'))
Vue.component('campaign-builder', () => import('./components/CampaignBuilder/CampaignBuilder.vue'))
Vue.component('contacts-form-embed', () => import('./components/Contacts/Forms/EmbedForm.vue'))
Vue.component('contacts-form-link', () => import('./components/Contacts/Forms/LinkForm.vue'))
Vue.component('code-editor-vue', () => import('./components/CodeEditor/CodeEditor.vue'))
Vue.component('onboarding', () => import('./components/Onboarding/Onboarding.vue'))
Vue.component('verify-emails-print', () => import('./components/Print/VerifyEmailsPrint.vue'))

import globalComponents from './global-components'
Vue.use(globalComponents)

import svgVue from './components/Icons/SvgVue.vue'
Vue.component('svg-vue', svgVue)

const vueApp = new Vue({
	i18n,
	router,
	data: {
		mobileNavOpened: false,
		panelOverlay: false,
		showSpaLoader: true,
		errorPage: null,
		modalOpened: false,
		loginModalOpened: false,
		showMessenger: false
	},
}).$mount('#vueApp')

window.Vue = Vue
window.vueApp = vueApp

// Bugsnag
var parsedHostname = window.location.hostname.split('.')
var hostnameSuffix = parsedHostname[parsedHostname.length - 1]
if(hostnameSuffix === 'cz' || hostnameSuffix === 'com') {
	Bugsnag.start({
		apiKey: '08f89614068d4bd024a7b3c50f21745e',
		appVersion: '1.0.0',
		plugins: [new BugsnagPluginVue()],
		onError: function (event) {
			if(event.originalError.isAxiosError) {
				if(event.originalError.response && event.originalError.response.status === 400) {
					return false
				}
				if(event.originalError.code === 'ECONNABORTED' || event.originalError.message === 'Network error') {
					return false
				}
			}
			return true
		}
	})
	const bugsnagVue = Bugsnag.getPlugin('vue')
	bugsnagVue.installVueErrorHandler(Vue)
}

resetCsrfCookieFromMeta();

import './legacy.js'
